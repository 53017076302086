import { FC, PropsWithChildren, useEffect, useState } from "react";
import BtnCardDown from "@/public/images/symbols/application/btnCardDown.svg";
import BtnCardUp from "@/public/images/symbols/application/btnCardUp.svg";
import SatelliteDish from "@/public/images/symbols/application/SatelliteDish.svg";
import Link from "@/public/images/symbols/application/link.svg";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import useTranslation from "next-translate/useTranslation";
import ButtonCustom from "@/components/_Common/ButtonCustom";
import { useRouter } from "next/router";
import DegreeEnum from "@/lib/enums/degree";
import { formatDate } from "@/lib/_common/formatedDate";
import { abitLink, currentCompanyYear } from "@/lib/configTest";
import { EntranceMasterExamItem } from "@Features/ExamsForm/MasterExamsForm/types/formTypes";
import { FetchMasterExamsData } from "@Features/ExamsForm/MasterExamsForm/model/services/FetchMasterExamsData";
import {
  getStudentDetailsActiveUserId,
  getUserDegree,
  getUserId,
  getUserYear,
} from "@Entities/User";
import styles from "./MasterCard.module.scss";
import { useSelector } from "react-redux";
import { getApplication } from "@Entities/Application/model/selectors/getApplication";
import { getSelectedPrograms } from "@Entities/Form";

export const MasterCard: FC<PropsWithChildren> = () => {
  const { t } = useTranslation("mystatement");

  const chosenPrograms = useSelector(getSelectedPrograms);
  const degree = useSelector(getUserDegree);
  const userId = useSelector(getUserId);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const application = useSelector(getApplication);
  const year = useTypedSelector(getUserYear);

  const [menuActive, setMenuActive] = useState<
    { active: boolean; number: number }[]
  >([]);

  const router = useRouter();

  useEffect(() => {
    chosenPrograms?.forEach((_, index) => {
      setMenuActive((prev) => [...prev, { active: false, number: index }]);
    });
  }, [chosenPrograms]);

  const btnMenuLogic = (index: number) => {
    const changedItem = menuActive.filter((item) => item.number === index);
    changedItem[0].active = !changedItem[0].active;
    const res = menuActive.map((item) => {
      return item.number === changedItem[0].number ? changedItem[0] : item;
    });
    setMenuActive(res);
  };

  const [exams, setExams] = useState<EntranceMasterExamItem[]>([]);

  useEffect(() => {
    const getExamsData = async () => {
      const response = await FetchMasterExamsData({
        userId: userId,
        studentDetailsId: studentDetailsId,
        degree: DegreeEnum.MASTER,
        year: Number(year) ?? currentCompanyYear,
      });

      setExams(response.exams);
    };
    getExamsData();
  }, []);

  return (
    <>
      <div className={styles.parentCards}>
        <div className={styles.heading}>
          <h1>{t("title")}</h1>
          <ButtonCustom
            className={styles.btn__prev + " ml-4"}
            rounded={true}
            variant="outline-primary"
            onClick={() => router.push("/personal/personally")}
          >
            {t("btn")}
          </ButtonCustom>
        </div>

        <div className={styles.info}>
          <span className={styles.info_program}>{t("info.program")}</span>
          <span className={styles.info_statement}>{t("info.financing")}</span>
          <span className={styles.info_amount + " d-none"}>
            {t("info.total")}
          </span>
          <span className={styles.info_placeRanking}>{t("info.rating")}</span>
          <span className={styles.info_status}>{t("info.status")}</span>
        </div>
        {chosenPrograms?.map(
          ({ id, program, financing, competitiveGroup }, index) => {
            const exam = exams?.find((exam) => exam.selected_program_id === id);

            return (
              <div
                key={id}
                className={
                  menuActive[index]?.active &&
                  menuActive[index]?.number === index
                    ? styles.card_active
                    : styles.card
                }
              >
                <div className={styles.card_name}>
                  <div className={styles.card_number}>
                    <span>{index + 1}</span>
                  </div>
                  <span className={styles.card_title}>{program.title}</span>
                </div>
                <div className={styles.card_bottom}>
                  {exam?.exam_date && (
                    <button
                      className={styles.card_btn}
                      onClick={() => btnMenuLogic(index)}
                    >
                      {menuActive[index]?.active &&
                      menuActive[index]?.number === index ? (
                          <BtnCardUp />
                        ) : (
                          <BtnCardDown />
                        )}
                    </button>
                  )}
                  <span>{program.directionOfEducation}</span>
                </div>
                <div className={styles.card_field}>
                  <span className={styles.card_accepted}>
                    {t(`financing.${financing}`)}
                  </span>
                  <span className={styles.card_count + " d-none"}>25</span>
                  <span className={styles.card_watch}>
                    <a
                      href={`${abitLink()}/rating/${degree}/${financing}/${competitiveGroup?.id}`}
                    >
                      {t("watch")}
                    </a>
                  </span>
                  <span className={styles.card_status}>
                    {!!application?.status && t(`status.${application.status}`)}
                  </span>
                  <span className={styles.card_status + " mt-5"}>
                    {!!application?.number && application.number}
                  </span>
                </div>
                <div
                  className={menuActive[index]?.active ? styles.menu : "d-none"}
                >
                  {exam?.exam_date && (
                    <div className={styles.menu_elem}>
                      <div className={styles.svg}>
                        <SatelliteDish />
                      </div>
                      <div className={styles.menu_items}>
                        <div className={styles.menu_item}>
                          {t("inCard.exam")}{" "}
                          <span>
                            {exam?.exam_date?.link && (
                              <a href={exam?.exam_date?.link}>
                                &nbsp;{t("link")} <Link />
                              </a>
                            )}
                          </span>
                        </div>
                        <div className={styles.menu_item}>
                          {t("inCard.date")}{" "}
                          <span>
                            {exam?.exam_date?.date &&
                              formatDate(
                                new Date(exam.exam_date?.date),
                                "default"
                              )}
                          </span>
                        </div>
                        {exam.exam_date?.comment && (
                          <div className={styles.menu_item}>
                            {t("inCard.comment")}{" "}
                            <span>{exam.exam_date?.comment}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
};
