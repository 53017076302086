import { useEffect, useState, FC, PropsWithChildren } from "react";
import BtnCardDown from "@/public/images/symbols/application/btnCardDown.svg";
import BtnCardUp from "@/public/images/symbols/application/btnCardUp.svg";
import FormLabel from "@/components/_Common/Form/Label";
import Cup from "@/public/images/symbols/application/cup.svg";
import useTranslation from "next-translate/useTranslation";
import Link from "@/public/images/symbols/application/link.svg";
import SatelliteDish from "@/public/images/symbols/application/SatelliteDish.svg";
import ButtonCustom from "@/components/_Common/ButtonCustom";
import { useRouter } from "next/router";
import UserService from "@/lib/services/user.service";
import { formatDate } from "@/lib/_common/formatedDate";
import { abitLink } from "@/lib/configTest";
import { useSelector } from "react-redux";
import { getSelectedPrograms } from "@Entities/Form";
import {
  getStudentDetailsActiveUserId,
  getUserDegree,
  getUserId,
} from "@Entities/User";
import styles from "./BachelorCard.module.scss";
import { getApplication } from "@Entities/Application/model/selectors/getApplication";

export const BachelorCard: FC<PropsWithChildren> = () => {
  const { t } = useTranslation("mystatement");

  const chosenPrograms = useSelector(getSelectedPrograms);
  const userId = useSelector(getUserId);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const degree = useSelector(getUserDegree);
  const application = useSelector(getApplication);

  const [menuActive, setMenuActive] = useState<
    { active: boolean; number: number }[]
  >([]);
  const router = useRouter();

  useEffect(() => {
    chosenPrograms?.forEach((_, index) => {
      setMenuActive((prev) => [...prev, { active: false, number: index }]);
    });
  }, [chosenPrograms]);

  const btnMenuLogic = (index: number) => {
    const changedItem = menuActive.filter((item) => item.number === index);
    changedItem[0].active = !changedItem[0].active;
    const res = menuActive.map((item) => {
      return item.number === changedItem[0].number ? changedItem[0] : item;
    });
    setMenuActive(res);
  };

  const [exams, setExams] = useState<IUserExams | null>(null);

  useEffect(() => {
    const getExamsData = async () => {
      const response = await UserService.getExams(
        userId,
        studentDetailsId,
        location.origin
      );

      setExams(response);
    };
    getExamsData();
  }, []);

  return (
    <>
      <div className={styles.parentCards}>
        <div className={styles.heading}>
          <h1>{t("title")}</h1>
          <ButtonCustom
            className={styles.btn__prev + " ml-4"}
            rounded={true}
            type="button"
            variant="link"
            onClick={() => router.push("/personal/personally")}
          >
            {t("btn")}
          </ButtonCustom>
        </div>

        <div className={styles.info}>
          <span className={styles.info_program}>{t("info.program")}</span>
          <span className={styles.info_statement}>{t("info.financing")}</span>
          <span className={styles.info_amount + " d-none"}>
            {t("info.total")}
          </span>
          <span className={styles.info_placeRanking}>{t("info.rating")}</span>
          <span className={styles.info_status}>{t("info.status")}</span>
        </div>
        {chosenPrograms?.map(
          (
            { program, financing, admissionCondition, competitiveGroup },
            index
          ) => {
            const exam = exams?.entranceTests?.at(index);

            return (
              <div
                key={index}
                className={
                  menuActive[index]?.active &&
                  menuActive[index]?.number === index
                    ? styles.card_active
                    : styles.card
                }
              >
                <div className={styles.card_name}>
                  <div className={styles.card_number}>
                    <span>{index + 1}</span>
                  </div>
                  <span className={styles.card_title}>{program.title}</span>
                </div>
                <div className={styles.card_bottom}>
                  <button
                    className={styles.card_btn}
                    onClick={() => btnMenuLogic(index)}
                  >
                    {menuActive[index]?.active &&
                    menuActive[index]?.number === index ? (
                        <BtnCardUp />
                      ) : (
                        <BtnCardDown />
                      )}
                  </button>
                  <span>{program.directionOfEducation}</span>
                </div>
                <div className={styles.card_field}>
                  <span className={styles.card_accepted}>
                    {t(`financing.${financing}`)}
                  </span>
                  <span className={styles.card_count + " d-none"}>25</span>
                  <span className={styles.card_watch}>
                    <a
                      href={`${abitLink()}/rating/${degree}/${financing}/${
                        competitiveGroup?.id
                      }`}
                    >
                      {t("watch")}
                    </a>
                  </span>
                  <span className={styles.card_status}>
                    {!!application?.status && t(`status.${application.status}`)}
                  </span>
                  <span className={styles.card_status + " mt-5"}>
                    {!!application?.number && application.number}
                  </span>
                </div>
                <div
                  className={
                    menuActive[index]?.active &&
                    menuActive[index]?.number === index
                      ? styles.menu
                      : "d-none"
                  }
                >
                  <div className={styles.menu_elem}>
                    <div className={styles.svg}>
                      <Cup />
                    </div>
                    <div className={styles.menu_items}>
                      <FormLabel className={styles.menu_labels}>
                        {t("inCard.admission")}
                      </FormLabel>
                      <u>
                        <span>
                          {t(`admissionCondition.${admissionCondition}`)}
                        </span>
                      </u>
                    </div>
                  </div>
                  {exam && (
                    <div className={styles.menu_elem}>
                      <div className={styles.svg}>
                        <SatelliteDish />
                      </div>
                      <div className={styles.menu_items}>
                        <div className={styles.menu_item}>
                          {t("inCard.exam")}{" "}
                          <span>
                            {exam?.exam_date?.link && (
                              <a href={exam?.exam_date?.link}>
                                &nbsp;{t("link")} <Link />
                              </a>
                            )}
                          </span>
                        </div>
                        <div className={styles.menu_item}>
                          {t("inCard.date")}{" "}
                          <span>
                            {exam?.date &&
                              formatDate(new Date(exam.date), "default")}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
};
