import { createSlice } from "@reduxjs/toolkit";
import { fetchApplication } from "../services/fetchApplication";
import { ApplicationReducerStateSchema } from "../types/applicationType";

const initialState: ApplicationReducerStateSchema = {
  error: null,
  isLoading: false,
  application: null,
};

export const applicationSlice = createSlice({
  name: "applicationSlice",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(fetchApplication.pending, (state) => {
      (state.isLoading = true), (state.error = null);
    });
    build.addCase(fetchApplication.fulfilled, (state, action) => {
      (state.isLoading = false), (state.application = action.payload);
    });
    build.addCase(fetchApplication.rejected, (state, action) => {
      (state.isLoading = false), (state.error = action.payload);
    });
  },
});

export const { reducer: applicationReducer } = applicationSlice;
export const { actions: applicationActions } = applicationSlice;
