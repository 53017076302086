import { FC, useState } from "react";
import styles from "./Question.module.scss";
import Expand from "@/public/images/symbols/right.svg";
import clsx from "clsx";

interface QuestionProps {
  questionText: string
  answerText: string
}

export const Question: FC<QuestionProps> = (props) => {
  const { questionText, answerText } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChangeIsOpen = () => {
    setIsOpen(prev => !prev);
  };

  const expandClasses: Record<string, boolean> = {
    [styles.faq__question_up]: isOpen,
    [styles.faq__question_down]: !isOpen,
  };

  return (
    <div
      className={styles.faq__block}
      onClick={handleChangeIsOpen}
    >
      <div className={styles.faq__question}>
        <p className={styles.faq__question__text}>
          {questionText}
        </p>
        <Expand className={clsx(expandClasses)} />
      </div>
      <div className={clsx(styles.faq__answer, isOpen && styles.faq__answer_active)}>
        <p>{answerText}</p>
      </div>
    </div>
  );
};
