import {
  useEffect,
  useState,
  FC,
  PropsWithChildren,
} from "react";

import BtnCardDown from "@/public/images/symbols/application/btnCardDown.svg";
import BtnCardUp from "@/public/images/symbols/application/btnCardUp.svg";
import FormLabel from "@/components/_Common/Form/Label";
import SatelliteDish from "@/public/images/symbols/application/SatelliteDish.svg";
import Humans from "@/public/images/symbols/application/humans.svg";
import Human from "@/public/images/symbols/application/humanProgram.svg";
import A from "@/public/images/symbols/application/A.svg";
import World from "@/public/images/symbols/application/world.svg";
import useTranslation from "next-translate/useTranslation";
import ButtonCustom from "@/components/_Common/ButtonCustom";
import StatusScientific from "@/components/_Common/Forms/Programs/Item/StatusScientific";
import Link from "@/public/images/symbols/application/link.svg";
import { useRouter } from "next/router";
import { formatDate } from "@/lib/_common/formatedDate";
import { abitLink } from "@/lib/configTest";
import { fetchPhdExamsData } from "./fetchPhdExamsData";
import styles from "./PostgraduateCard.module.scss";
import { getSelectedPrograms } from "@Entities/Form";
import {
  getStudentDetailsActiveUserId,
  getUserId,
} from "@Entities/User";
import { useSelector } from "react-redux";
import { getApplication } from "@Entities/Application/model/selectors/getApplication";

interface PhdExam {
  discipline_id: number;
  exam_date: {
    comment: string;
    commisison_id: number;
    date: string;
    id: number;
    link: string;
    wave: string;
  };
  postgraduate_exam_date: string;
  scores: number;
  selected_program_id: number;
  type_id: number;
  year: number;
}

export const PostgraduateCard: FC<PropsWithChildren> = () => {
  const { t } = useTranslation("mystatement");

  const chosenPrograms = useSelector(getSelectedPrograms);
  const userId = useSelector(getUserId);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const application = useSelector(getApplication);

  const [menuActive, setMenuActive] = useState<
    { active: boolean; number: number }[]
  >([]);
  const router = useRouter();

  useEffect(() => {
    chosenPrograms?.forEach((_, index) => {
      setMenuActive((prev) => [...prev, { active: false, number: index }]);
    });
  }, [chosenPrograms]);

  const btnMenuLogic = (index: number) => {
    const changedItem = menuActive.filter((item) => item.number === index);
    changedItem[0].active = !changedItem[0].active;
    const res = menuActive.map((item) => {
      return item.number === changedItem[0].number ? changedItem[0] : item;
    });
    setMenuActive(res);
  };
  const [exams, setExams] = useState<PhdExam[]>([]);

  useEffect(() => {
    const getExamsData = async () => {
      const response = await fetchPhdExamsData({
        userId: userId,
        studentDetailsId: studentDetailsId,
      });

      setExams(response);
    };
    getExamsData();
  }, []);

  const languageExam = exams?.at(0);

  return (
    <>
      <div className={styles.parentCards}>
        <div className={styles.heading}>
          <h1>{t("title")}</h1>
          <ButtonCustom
            className={styles.btn__prev + " ml-4"}
            rounded={true}
            variant="outline-primary"
            onClick={() => router.push("/personal/personally")}
          >
            {t("btn")}
          </ButtonCustom>
        </div>

        <div className={styles.info}>
          <span className={styles.info_program}>{t("info.program")}</span>
          <span className={styles.info_statement}>{t("info.financing")}</span>
          <span className={styles.info_amount + " d-none"}>
            {t("info.total")}
          </span>
          <span className={styles.info_placeRanking}>{t("info.rating")}</span>
          <span className={styles.info_status}>{t("info.status")}</span>
        </div>
        {chosenPrograms?.map(
          (
            {
              id,
              program,
              dissertationTopic,
              faculty,
              supervisor,
              supervisorConfirm,
              financing,
              competitiveGroup,
            },
            index
          ) => {
            const specialtyExam = exams?.find(
              (exam) =>
                exam.selected_program_id === id ||
                exam.selected_program_id === id - 1
            );

            return (
              <div
                key={index}
                className={
                  menuActive[index]?.active &&
                  menuActive[index]?.number === index
                    ? styles.card_active
                    : styles.card
                }
              >
                <div className={styles.card_name}>
                  <div className={styles.card_number}>
                    <span>{index + 1}</span>
                  </div>
                  <span>{program.title}</span>
                </div>
                <div className={styles.card_bottom}>
                  <button
                    className={styles.card_btn}
                    onClick={() => btnMenuLogic(index)}
                  >
                    {menuActive[index]?.active &&
                    menuActive[index]?.number === index ? (
                        <BtnCardUp />
                      ) : (
                        <BtnCardDown />
                      )}
                  </button>
                  <span className={styles.card_title}>
                    {program.directionOfEducation}
                  </span>
                </div>
                <div className={styles.card_field}>
                  <span className={styles.card_accepted}>
                    {t(`financing.${financing}`)}
                  </span>
                  <span className={styles.card_count + " d-none"}>25</span>
                  <span className={styles.card_watch}>
                    <a
                      href={`${abitLink()}/rating/phd/${financing}/${competitiveGroup?.id}`}
                    >
                      {t("watch")}
                    </a>
                  </span>
                  <span className={styles.card_status}>
                    {!!application?.status && t(`status.${application.status}`)}
                  </span>
                  <span className={styles.card_status + " mt-5"}>
                    {!!application?.number && application.number}
                  </span>
                </div>
                <div
                  className={
                    menuActive[index]?.active &&
                    menuActive[index]?.number === index
                      ? styles.menu
                      : "d-none"
                  }
                >
                  <div className={styles.menu_elem}>
                    <div className={styles.svg}>
                      <Humans />
                    </div>
                    <div className={styles.menu_items}>
                      <FormLabel className={styles.menu_labels}>
                        {t("inCard.faculty")}
                      </FormLabel>
                      <u>
                        <span>{faculty?.title}</span>
                      </u>
                    </div>
                  </div>

                  <div className={styles.menu_elem}>
                    <div className={styles.svg}>
                      <Human />
                    </div>
                    <div className={styles.menu_items}>
                      <FormLabel className={styles.menu_labels}>
                        {t("inCard.sciencific")}
                        <StatusScientific
                          supervisorConfirm={supervisorConfirm}
                          where={router.asPath}
                        />
                      </FormLabel>
                      <u>
                        <span>
                          {`${supervisor?.lastName} ${supervisor?.firstName} ${supervisor?.middleName}`}
                        </span>
                      </u>
                    </div>
                  </div>

                  {specialtyExam?.exam_date && (
                    <div className={styles.menu_elem}>
                      <div className={styles.svg}>
                        <SatelliteDish />
                      </div>
                      <div className={styles.menu_items}>
                        <div className={styles.menu_item}>
                          {t("inCard.specialtyExam")}{" "}
                          <span>
                            {specialtyExam?.exam_date.link && (
                              <a href={specialtyExam?.exam_date.link}>
                                &nbsp;{t("link")} <Link />
                              </a>
                            )}
                          </span>
                        </div>
                        <div className={styles.menu_item}>
                          {t("inCard.date")}{" "}
                          <span>
                            {specialtyExam.exam_date.date
                              ? specialtyExam &&
                                formatDate(
                                  new Date(specialtyExam.exam_date.date),
                                  "default"
                                )
                              : specialtyExam.exam_date.wave}
                          </span>
                        </div>
                        {specialtyExam.exam_date.comment && (
                          <div className={styles.menu_item}>
                            {t("inCard.comment")}{" "}
                            <span>{specialtyExam.exam_date.comment}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className={styles.menu_elem}>
                    <div className={styles.svg}>
                      <A />
                    </div>
                    <div className={styles.menu_items}>
                      <FormLabel className={styles.menu_labels}>
                        {t("inCard.dissertation")}
                      </FormLabel>
                      <u>
                        <span>{dissertationTopic}</span>
                      </u>
                    </div>
                  </div>

                  {languageExam?.exam_date && (
                    <div className={styles.menu_elem}>
                      <div className={styles.svg}>
                        <World />
                      </div>
                      <div className={styles.menu_items}>
                        <div className={styles.menu_item}>
                          {t("inCard.languageExam")}{" "}
                          <span>
                            {languageExam?.exam_date.link && (
                              <a href={languageExam?.exam_date.link}>
                                &nbsp;{t("link")} <Link />
                              </a>
                            )}
                          </span>
                        </div>
                        <div className={styles.menu_item}>
                          {t("inCard.date")}{" "}
                          <span>
                            {languageExam.exam_date.date
                              ? languageExam &&
                                formatDate(
                                  new Date(languageExam.exam_date.date),
                                  "default"
                                )
                              : languageExam.exam_date.wave}
                          </span>
                        </div>
                        {languageExam.exam_date.comment && (
                          <div className={styles.menu_item}>
                            {t("inCard.comment")}{" "}
                            <span>{languageExam.exam_date.comment}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
};
