import { FC, PropsWithChildren, useEffect, useState } from "react";
import styles from "./style.module.scss";
import useTranslation from "next-translate/useTranslation";

type StatusScientificProps = {
  supervisorConfirm?: boolean | null,
  where: string;
};

const StatusScientific: FC<PropsWithChildren<StatusScientificProps>> = ({ supervisorConfirm, where }) => {
  const [status, setStatus] = useState<string>("");
  const { t } = useTranslation("status_scientific");

  useEffect(() => {
    switch (supervisorConfirm) {
      case null:
        setStatus("consideration");
        break;
      case true:
        setStatus("agreed");
        break;
      case false:
        setStatus("rejected");
    }
  }, [supervisorConfirm]);

  return (
    <div className={where !== "/" ? styles[`${status}`] : styles[`${status}_main`]}>
      <span className={styles.status_description}>{t(`${status}`) ?? ""} </span>
    </div>
  );
};

export default StatusScientific;
