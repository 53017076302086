import { api } from "@Shared/api/createAxiosApi";
import { captureException } from "@sentry/nextjs";

interface LanguagePhdExam {
  scores: number | null,
  year: number,
  postgraduate_exam_date: string,
  discipline_id: number,
  type_id: number,
  selected_program_id: null
}

interface SpecialPhdExam {
  discipline_id: 0,
  type_id: 1,
  year: 0,
  postgraduate_exam_date: "string",
  scores: 0,
  selected_program_id: 0
}

interface EntrancePhdExamsSchema {
  is_have_exams: boolean,
  language_exam: LanguagePhdExam,
  specialty_exams: (LanguagePhdExam | SpecialPhdExam)[]
}
interface PhdExamsResponse {
  result: EntrancePhdExamsSchema
}

interface RequestOptions {
    userId: number
    studentDetailsId: number
}

export const fetchPhdExamsData = async (options: RequestOptions): Promise<any> => {
  const { userId, studentDetailsId } = options;

  try {
    const url = `/users/${userId}/studentDetails/${studentDetailsId}/forms/phd_exams`;
    const response = await api.get<PhdExamsResponse>(url);

    const { language_exam, specialty_exams } = response.data.result;

    const phdExamBad = {
      scores: null,
      year: 2023,
      postgraduate_exam_date: "",
      discipline_id: undefined,
      type_id: 4,
      selected_program_id: null,
    };

    specialty_exams.unshift(language_exam ?? phdExamBad);

    return specialty_exams;
  } catch (error) {
    captureException(error);
    return {
      exams: [],
      is_have_exams: false,
    };
  }
};
